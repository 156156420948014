<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			search-input
			@rowSelected="rowSelected($event)"
			height="auto"
            title="Opciones Avanzadas"
			no-full 
			ref="gridReceptionFresh"
		> 
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col cols="12" md="3" lg="3">
							<s-select-definition
								:def="1342"
								label="Nave"
								v-model="filter.TypeDestiny"
								readonly
							/>
						</v-col>
						<v-col cols="6" md="3" lg="3">
							<s-date
								label="Fecha Inicio"
								v-model="filter.cDateInitial"
							></s-date>
						</v-col>
						<v-col cols="6" md="3" lg="3">
							<s-date
								label="Fecha Fin"
								v-model="filter.cDateFin"
							></s-date>
						</v-col>
						<v-col cols="12" md="3" lg="3">
							<s-select-definition
								:def="1230"
								label="Planta Procesadora"
								v-model="filter.TypeProcessingPlant"
								clearable
							/>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1172"
								label="Tipo Cultivo"
								v-model="filter.TypeCrop"
							/>
						</v-col>
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1173"
								label="Cultivo"
								v-model="filter.TypeCultive"
							/>
						</v-col>

						<v-col cols="12" md="4" lg="3">
							<s-select-variety
								clearable
								:cultiveID="filter.TypeCultive"
								label="Variedad"
								full
								v-model="filter.VrtID"
							/>
						</v-col>
					</v-row>

					<v-row style="margin-left: 5px">
						<v-col cols="6" sm="2">
							<v-btn
								@click="openDerive  = true"
								small
								color="success"
								rounded
                                :disabled="disabled"
							>
								Cambiar Guia Cosecha
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</template>





			
		</s-crud>


		<v-dialog persistent v-model="openDerive" v-if="openDerive" width="500">
			<v-card>
				<v-col>
					<v-row>
						<h2 class="mt-6 ml-3">Cambiar Guia Cosecha</h2>
						<v-spacer></v-spacer>
						<v-btn
							class="mt-3 mr-3"
							@click="openDerive = false"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
					</v-row>

					<v-row>
						<v-col class="pb-0" cols="12">
							<s-text label="N° Guia Cosecha" :mask="maskFormatGuideHarvested" v-model="itemsSelected.RcfNumberHarvestGuide"></s-text>
						</v-col>
						<v-col class="pb-0" cols="12" v-if="$fun.hasSpecialPermission('CONGELADO')">
							<s-text label="N° Guia Remision" :mask="maskFormatGuideRemission" v-model="itemsSelected.RcfNumberReferralGuide"></s-text>
						</v-col>
					</v-row>
					<v-col>
						<v-row justify="center" class="pt-3">
							<v-spacer></v-spacer>
							<v-btn class="ml-3" dark color="primary" @click="save()"
								>Guardar</v-btn
							>
						</v-row>
					</v-col>
				</v-col>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

	import _sDiscartDamage from "../../../services/FreshProduction/PrfLotDiscartDamageService.js";
    import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
	import _sGenParam from "../../../services/General/ParameterService";



    import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";

	export default {
		components: {
            SSelectVariety
		},

		data() {
			return {
				openDerive: false,
                disabled: true,
                item: {},
				filter: {},
				config: {
					service: _sDiscartDamage,
					model: {
						RcfID: "ID",
						RcfDateReception: "date",
					},
					headers: [

						{ text: "Nro. Lote.", value: "RcfID", width: 100 },
						{
							text: "Fec. Recep..",
							value: "RcfDateReception",
							width: 140,
						},
						{
							text: "Tipo Servicio",
							value: "TypeServiceName",
							width: 100,
						},
						{ text: "Productor", value: "PrdCardName", width: 260 },
						{ text: "Fundo", value: "FagName", width: 260 },
						{ text: "Lote", value: "FltName", width: 100 },
						{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
						{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
						{ text: "Variedad", value: "VrtName", width: 160 },
						{ text: "Cod. Senasa", value: "FltCodSenasa", width: 160 },
						{
							text: "Guia Cosecha",
							value: "RcfNumberHarvestGuide",
							width: 160,
						},
						{
							text: "Guia Remision",
							value: "RcfNumberReferralGuide",
							width: 160,
						},
						{
							text: "Planta Procesdora",
							value: "TypeProcessingPlantName",
							width: 160,
						},
						{ text: "Empacadora", value: "TypeBalerName", width: 160 },
					],
				},
                itemsSelected: {},
                maskFormatGuideHarvested: "",
			};
		},

        created() {
            this.loadParameter()
        },

		methods: {

            loadParameter() {
				_sGenParam
					.search(
						{ PrmName: "maskFormatGuideHarvested" },
						this.$fun.getUserID()
					)
					.then(resp => {
						if (resp.status == 200) {
							this.maskFormatGuideHarvested = resp.data.PrmValue;
						}
					});
			},
			

			rowSelected(item) {
				if(item.length > 0){
					if(item !== undefined){
                        this.itemsSelected = item[0]
                        console.log(this.itemsSelected);
						this.disabled = false
					}
				}else{this.disabled  = true}
				
				
			},

            save(){
                if(this.itemsSelected.RcfNumberHarvestGuide.length == 0){
                    this.$fun.alert("Ingrese Guia se cosecha", "warning")
                    return
                }
				if(this.itemsSelected.RcfNumberReferralGuide.length == 0){
                    this.$fun.alert("Ingrese Guia se remision", "warning")
                    return
                }
				

                this.$fun
					.alert(
						"¿ Esta seguro de Modifcar N° Guia de Cosecha al Lote Producción: " + this.itemsSelected.RcfID+" ?",
						"question"
					)
					.then(x => {
						if (x.value) {
							this.itemsSelected.RcfState = 30;
							this.itemsSelected.UsrCreateID = this.$fun.getUserID();

							_sReceptionFresh
								.save(this.itemsSelected, this.$fun.getUserID())
								.then(resp => {
									if (resp.status == 200) {
										this.$fun.alert("Datos actualizados correctamente", "success");
                                        this.openDerive = false
										this.$refs.gridReceptionFresh.refresh();
									}
								});
						}
					});
            }
		},

		mounted(){
			// filter.TypeDestiny
			let CONGELADO = this.$fun.hasSpecialPermission('CONGELADO');

			console.log("CONGELADO" , CONGELADO)

			if (CONGELADO) {
				this.filter.TypeDestiny = 2;
			} 
		}
	};
</script>
