<template>
    <OptionsAdvanced />
</template>

<script>
  
import OptionsAdvanced from "@/views/FreshProduction/RawMaterialReception/PrfOptionsAdvanced.vue";
 
 export default {
    components: {
        OptionsAdvanced
    },
}

</script>