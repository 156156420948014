import Service from '../Service';

const resource = "lotdiscartdamage/"

export default {

    save(ldd, requestID) {
        return Service.post(resource + 'save', ldd, {
            params: { requestID }
        });
    },

    transferDamage(obj, requestID) {
        return Service.post(resource + 'transferDamage', obj, {
            params: { requestID }
        });
    },

    getDamageList(TypeCultive, RcfID, requestID) {
        return Service.post(resource + "getDamageList", {}, {
            params: { TypeCultive, RcfID, requestID },
        });
    },

    getDamageHeader(RcfID, requestID) {
        return Service.post(resource + "getDamageHeader", {}, {
            params: { RcfID, requestID },
        });
    },

    /*  list(RcfID, requestID) {
         return Service.post(resource + 'list', {}, {
             params: { RcfID, requestID }
         });
     }, */

    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID }
        });
    },
}